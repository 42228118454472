import React from "react";
import { Theme, Box, IconButton, styled, Dialog, TextField, Chip, TextareaAutosize, DialogContent, DialogTitle, Button, Typography } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import CloseIcon from '@material-ui/icons/Close';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
const CopyImage = require("./copy.svg");
const viewBtn = require("./view_.png") 
const BackBtn =require('./back.svg')
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
interface ShareLinkProps {
    isOpen: boolean;
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickChatButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
    shareLink: string;
    isChatSend: boolean;
}

interface OptionType {
    account_id?:number;
    firstName: string;
    lastName: string;
    photo: string;
}

interface ShareMenuProps {
    isOpen: boolean;
    profiles :OptionType[];
    shareData:OptionType[];
    onShareDataChange: (event: React.ChangeEvent<{}>, value: OptionType[]) => void;
    chatMessage: string;
    onChangeChatMessage: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClickBackButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClickSendChat: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledShareChatDial = styled(Dialog)({
    "& .headingText": {
      fontFamily: "Silika-Medium !important"
    },
    '& .MuiDialog-container':{
      background:"#ffffffc4"
    },
    '& .MuiPaper-elevation24':{
      boxShadow:"none"
    }
  })

const StyledDialogTitle = styled(DialogTitle)(({theme}:{theme: Theme}) => ({
    padding: "16px 24px 0 24px",
    "& h2":{
        display: "inline-flex",
        width: "100%",
        alignItems: "center"
    },
    "& p":{
        width: "100%",
        textAlign: "center",
        fontFamily: "Silika-Medium",
        fontSize: "24px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px !important",
            lineHeight: "19.3px !important"
        }
    },
    "& .closeIcon":{
        marginRight: "0px",
        marginLeft: "auto"
    },
    "& .backArrowIcon":{
        marginLeft: "0px",
        marginRight: "auto"
    }
}));

const StyledDialogContent = styled(DialogContent)(({theme}:{theme: Theme}) =>({
    display: "flex",
    flexFlow: "column",
    padding: "0 24px",
    width: "552px",
    [theme.breakpoints.down('sm')]: {
        width: "calc(100% - 48px)",
        oveflow: "hidden"
    },
    "& .copyField":{
        margin: "16px auto 24px auto",
        display: "inline-flex",
        border: "1px solid #DFDFDF",
        borderRadius: "2px",
        height: "44px",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        width: "550px",
        "& .link":{
            width: "90%",
            overflowX: "hidden",
            marginLeft: "11px",
            fontFamily: "Silika-Medium",
            [theme.breakpoints.down('sm')]: {
                fontSize: "16px !important",
                lineHeight: "19.3px !important"
            }
        },
        "& img":{
            height: "22px",
            width: "19px",
        },
        [theme.breakpoints.down('sm')]: {
            width: "99%",
            margin: "16px 0 24px 0",
        }
    },
    "& .linkCopied":{
        display: "flex",
        alignItems: "center",
        marginBottom: "24px",
        height: "42px",
        borderLeft: "4px solid #34D399",
        background: "#F8F8F8",
        borderRadius: "2px",
        "& p":{
            marginLeft: "12px",
            fontSize: "12px",
            fontFamily: "Silika-Light"
        }
    },
    "& .copyIcon":{
        padding: "10px",
        marginLeft: "auto"
    },
    "& .errorLinkCopied":{
        display: "flex",
        alignItems: "center",
        marginBottom: "24px",
        height: "42px",
        borderLeft: "4px solid #DC2626",
        background: "#F8F8F8",
        borderRadius: "2px",
        "& p":{
            marginLeft: "12px",
            fontSize: "12px",
            fontFamily: "Silika-Light",
            color: "#DC2626"
        }
    },
    "& .chatIcon":{
        margin: "0 auto",
        borderRadius: "8px",
        boxShadow: "0px 2px 8px 0px #00000014",
        height: "56px",
        width: "56px",
        marginBottom: "27px"
    },
    "& .shareTo":{
        marginTop: "24px",
        fontFamily: "Silika-Medium",
        fontSize: "16px",
        color:"black",
        marginBottom: "4px",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px !important",
            lineHeight: "19.3px !important"
        }
    },
    "& .MuiButton-root.Mui-disabled":{
        background: "#DFDFDF !important"
    }
}));

const StyledAutoComplete = styled("div")(({theme}:{theme: Theme}) => ({
    "& *":{
        fontFamily: "Silika-Medium"
    },
    "& input": {
        "&::placeholder": {
            color: "#9B9B9D",
        }
    },
    "& input.MuiInputBase-input":{
        [theme.breakpoints.down('sm')]: {
            width: "calc(100% - 48px) !important"
        }
    },
    "& .MuiChip-label > .chip-box-style":{
        display: "inline-flex",
        "& img":{
            marginRight: "8px"
        }
    }
}));

const StyledTextArea = styled("div")(({theme}: {theme: Theme}) => ({
    margin: "24px 0",
    "& textarea":{
        resize: "none",
        padding: "10px 8px",
        width: "100%",
        border: "1px solid #DFDFDF",
        borderRadius: "2px",
        fontFamily: "Silika-Medium",
        fontSize: "16px",
        "&::placeholder": {
            color: "#9B9B9D",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px !important",
            lineHeight: "19.3px !important"
        }
    }
}));

const StyledBox = styled(Box)({
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "& .imageLogoStyle": {
      border: "1px solid #DFDFDF",
      borderRadius: "100%"
    },
    "& .imageName":{
        lineHeight: "24px"
    }
});

const StyledButton = styled(Button)({
    textTransform: "none",
    background: "#0D0C22 !important",
    width: "120px",
    height: "50px",
    marginRight: "0px",
    marginLeft: "auto",
    color: "#fff !important",
    borderRadius: "2px",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    marginBottom: "27px"
});

export const ShareLinkDialog = (props: ShareLinkProps) => {
    const [isLinkCopied, setIsLinkCopied] = React.useState(false);
    const [isLinkCopiedError, setIsLinkCopiedError] = React.useState(false);

    const handleCopyLinkButton = () => {
        setIsLinkCopied(!isLinkCopied);
        navigator.clipboard.writeText(props.shareLink)
      .then(() => {
        setIsLinkCopied(true);
      })
      .catch(() => { setIsLinkCopiedError(true)});
    }

    const linkCopied = isLinkCopied || isLinkCopiedError ;
    return (
        <StyledShareChatDial open={props.isOpen}
            aria-labelledby="share-link-dialog-title"
            aria-describedby="share-link-dialog-description"
            onClose={props.onClose}
        >
            <StyledDialogTitle id="share-link-dialog-title">
                <Typography>Share</Typography>
                <Box className="closeIcon" onClick={props.onClose}><img src={viewBtn} height={14} width={14}/></Box>
            </StyledDialogTitle>
            <StyledDialogContent>
                <div className="copyField">
                    <span className="link">{props.shareLink}</span>
                    <IconButton className="copyIcon" onClick={handleCopyLinkButton}><img src={CopyImage} alt="copyImage" /></IconButton>
                </div>
                {linkCopied && <div className={isLinkCopiedError? "errorLinkCopied": "linkCopied"}><Typography className="isNoErrorTextStyle">Link copied!</Typography></div>}
                {!linkCopied && props.isChatSend && <div className="linkCopied"><Typography className="isNoErrorTextStyle">Message Sent!</Typography></div>}
                <Button data-test-id="clickChatOpen" className="chatIcon" onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setIsLinkCopied(false);
                    setIsLinkCopiedError(false);
                    props.onClickChatButton(event);
                }}><ChatBubbleOutlineIcon/></Button>
            </StyledDialogContent>

        </StyledShareChatDial>
    )
};

export const ShareMenuDialog = (props: ShareMenuProps) => {
    return (
        <Dialog open={props.isOpen}
            aria-labelledby="share-menu-dialog-title"
            aria-describedby="share-menu-dialog-description"
            onClose={props.onClose}
            PaperProps={{
                style: {
                  boxShadow: "none",
                },
              }}
        >
            <StyledDialogTitle id="share-menu-dialog-title">
                <Box onClick={props.onClickBackButton} className="backArrowIcon"><img src={BackBtn} height={25} width={14} /></Box>
                <Typography>Share</Typography>
                <Box className="closeIcon" onClick={props.onClose}><img src={viewBtn} height={14} width={14}/></Box>
            </StyledDialogTitle>
            <StyledDialogContent>
                <Typography className="shareTo">To:</Typography>
                <StyledAutoComplete>
                    <Autocomplete
                        multiple
                        value={props.shareData}
                        data-test-id="shareToProfile"
                        options={props.profiles}
                        getOptionLabel={(option: { account_id?:number , firstName: string, lastName: string, photo: string }) => `${option.photo} ${option.firstName} ${option.lastName}`}
                        renderOption={(autoCompleteProps, option :{account_id?:number , firstName: string, lastName: string, photo: string}) => (
                        <StyledBox component={"span"} {...autoCompleteProps}>
                            <img className="imageLogoStyle" width={24} height={24} src={option?.photo} alt="AvtarLogo" />
                            <Box className="imageName">{`${option?.firstName} ${option?.lastName}`}</Box>
                        </StyledBox>
                        )}
                        renderTags={(value, getTagProps) => (
                            value?.map((option, index) => (
                            <Chip
                                label={
                                <StyledBox component="span" className="chip-box-style">
                                    <img className="imageLogoStyle" width={24} height={24} src={option?.photo} alt="AvatarLogo" />
                                    <Box className="imageName">{option.firstName} {option.lastName}</Box>
                                </StyledBox>
                                }
                                {...getTagProps({ index })}
                                variant="outlined"
                            />
                            ))
                        )}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search by name"
                            />
                        )}
                        onChange={props.onShareDataChange}
                    />
                </StyledAutoComplete>
                <StyledTextArea>
                    <TextareaAutosize
                        className="textAreaStyle"
                        aria-label="minimum height"
                        minRows={4}
                        value={props.chatMessage}
                        data-test-id="shareChat"
                        onChange={props.onChangeChatMessage}
                        placeholder="Write a message"
                    />
                </StyledTextArea>
                <StyledButton disabled={props.chatMessage.length === 0 || props.shareData.length === 0} onClick={props.onClickSendChat} data-test-id="sendChatBtn">
                    Send
                </StyledButton>
            </StyledDialogContent>
        </Dialog>
    )
}