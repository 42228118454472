import React from "react";
// Customizable Area Start
import {
  Typography,
  styled, Avatar, Grid, Theme, CircularProgress
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import HeaderNavbar from "../../../components/src/Navbar";
import Carousel from "react-elastic-carousel";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import AdvanceSearch from "./components/AdvanceSearch.web";
import ShareIcon from '@material-ui/icons/Share';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { orangeBanner } from "../../landingpage/src/assets";
import { customArrow } from "../../../components/src/CustomArrow.web";
import { ShareLinkDialog, ShareMenuDialog } from "../../../components/src/ShareDialogs.web";
import {
  dislikeImage,
  likeImage
} from "../../customisableuserprofiles/src/assets";
import CatalogueController, { Profile, PostImage } from "./CatalogueController";
import { returnTruthyArray } from "../../../components/src/HelperUtils";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class FindTalentCatalogue extends CatalogueController {

  renderNoResultsFound = () => {
    return (
      <div className="emptyContainer" data-test-id="noResultsDiv">
         <Typography className="p1">Sorry, no results found.</Typography>
         <Typography className="p2">Unfortunately no results meet these search criteria.</Typography>
      </div>
    )
  }

  renderSaveLikeShare = (isLiked: boolean, isSaved: boolean, imageId: number, postId: number, profileId: string) => {
    return (
        <div className="actionIcons">
            {isLiked ? <FavoriteIcon data-test-id="likePostIcon" onClick={(event) => {
              event.stopPropagation();
              this.handleLikePost(!isLiked, imageId, profileId)
            }} />: <FavoriteBorderIcon data-test-id="likePostIcon" onClick={(event) => {
              event.stopPropagation();
              this.handleLikePost(true, imageId, profileId)
            }}/> }
            <ShareIcon onClick={(event: React.MouseEvent<SVGSVGElement | HTMLButtonElement>) => {
              event.stopPropagation();
              this.handleClickShareButton(event, "post", postId.toString(), profileId)
            }}/>
            {isSaved ? <BookmarkIcon data-test-id="favouritePostIcon"  onClick={(event) => {
              event.stopPropagation();
              this.handleFavouritePost(!isSaved, imageId, profileId)
            }} /> : <BookmarkBorderIcon data-test-id="favouritePostIcon" onClick={(event) => {
              event.stopPropagation()
              this.handleFavouritePost(true, imageId, profileId)
            }} />}
        </div>
    )
  }

  renderCount = () => {
    const isFilterEnabled = this.isFilterInProgress();
    if(isFilterEnabled){
      return <> &#40;{`${this.state.profileData.length}`}&#41;</>
    }
    return "";
  }
  isFilterInProgress = () => {
    const {advanceSearchText, selectedSkills, selectedProfessions, selectedSeniorityLevels, selectedWorkTypes, selectedCountries, minRate, maxRate} = this.state;
    let isFilterEnabled = false;
    if(advanceSearchText.length !== 0 || selectedSkills.length > 0 || selectedProfessions.length > 0 || selectedSeniorityLevels.length > 0 || selectedWorkTypes.length > 0 || selectedCountries.length > 0 || ((minRate !== undefined) && (maxRate !== undefined) && (minRate < maxRate))){
        isFilterEnabled = true;
    }
    return isFilterEnabled;
  }

  renderMain = () => {
    const {allLikes, favPeople, count, profileData} = this.state;
    const dataLength = profileData.length;
    const isFilterEnabled = this.isFilterInProgress();
    if(isFilterEnabled && dataLength === 0){
      return <div className="emptyDiv">{this.renderNoResultsFound()}</div>
    }else {
      if(dataLength === 0){
        return <></>
      }else{
        return <div className="profilesContainer" data-test-id="profileCards">{profileData.slice(0, count).map((profile: Profile) => {
          const name = profile.attributes.first_name + " " + profile.attributes.last_name;
          const postImages = profile.attributes.post_images;
          const lengthIsGreater = postImages.length > 3;
          const isLiked = allLikes.some(
            (like: any) =>
              like.attributes.likeable_id === profile.attributes.id
          );
          const headline = profile.attributes.headline &&
          `${profile.attributes.headline} ` +
          (profile.attributes.hourly_rate_from && profile.attributes.hourly_rate_to
            ? `| £${profile.attributes.hourly_rate_from} - £${profile.attributes.hourly_rate_to}`
            : ''
          )
          const isFavPeople = favPeople.findIndex(x => x.favouriteable_id === profile.attributes.id) !== -1;
          const profession = (returnTruthyArray(profile.attributes.profession) as string[]).join("   |   ")
          const preferedWorkType = (returnTruthyArray(profile.attributes.work) as string[]).join("   |   ")
          return <div className="profileContainer" onClick={() => this.handleNavigateToProfile(profile.id)}>
          <div className="profileInfoUpper">
              <div className="profileInfoDiv">
                  <div className={`${postImages.length > 0 ? "": "marginTop"} userProfileImgDiv`}>
                      <ProfileAvatar className="userProfileImg" src={profile.attributes.photo}>
                              {name}
                      </ProfileAvatar>
                  </div>
              </div>
              <div className="profileDetailsDiv">
                  <div className="profileDetailHeader">
                  <div className="userNameMob">
                      <Typography className="userName">{name}</Typography>
                      <Typography className="description">
                    {headline}
                      </Typography>
                  </div>
                  <div className="availabilityIcons">
                    <span className="shortlist">Shortlist</span>
                    <span className="available">Available</span>
                  </div>
                  </div>
                  <div className="detailsWrapper">
                      <span className="borderLine" />
                      <div className="detailsRow">
                          <div className="detail row1">
                              <Typography className="detailTitle">Level:</Typography>
                              <div className="detailValue">
                                  <Typography>{profile.attributes.seniority_level}</Typography>
                              </div>
                          </div>
                          <div className="detail row2">
                              <Typography className="detailTitle">Location:</Typography>
                              <div className="detailValue">
                                  <LocationOnIcon/>
                                  <Typography> {profile.attributes.county + ", "+ profile.attributes.country}</Typography>
                              </div>
                          </div>
                      </div>
                      <span className="borderLine" />
                      <div className="detailsRow">
                          <div className="detail row1">
                              <Typography className="detailTitle">Type:</Typography>
                              <div className="detailValue">
                                  <Typography>{preferedWorkType}</Typography>
                              </div>
                          </div>
                          <div className="detail row2">
                              <Typography className="detailTitle">Professions:</Typography>
                              <div className="detailValue">
                                  <Typography>{profession}</Typography>
                              </div>
                          </div>
                      </div>
                      <span className="borderLine" />
                      <div className="detailsRow">
                      <img src={isLiked ? dislikeImage : likeImage} onClick={(event) => {
                        event.stopPropagation();
                        this.handleThumbsUp(profile.attributes.id.toString())
                      }} className="actionIcons" data-test-id="likeProfile"/>
                      <div className="actionIcons">
                          <ChatBubbleOutlineIcon/>
                          <ShareIcon onClick={(event: React.MouseEvent<SVGSVGElement | HTMLButtonElement>) => {
                            event.stopPropagation();
                            this.handleClickShareButton(event, "profile", profile.id)
                          }} data-test-id="shareProfile"/>
                          {isFavPeople ? <BookmarkIcon data-test-id="favouriteProfile" onClick={(event) => {
                            event.stopPropagation();
                            this.handleFavouritePeople(!isFavPeople, profile.attributes.id)
                          }} /> 
                          :<BookmarkBorderIcon data-test-id="favouriteProfile" onClick={(event) => {
                            event.stopPropagation()
                            this.handleFavouritePeople(true, profile.attributes.id)
                          }} />}
                      </div>
                      </div>
                  </div>
              </div>
          </div>
          <StyledCarousel>
              <div className="carouselMain">
                  <Carousel
                  renderArrow={(props) => customArrow({ ...props, lenghIsGreater: lengthIsGreater })}
                  isRTL={false}
                  itemsToShow={3}
                  data-test-id="carousel-main-div"
                  className="carouselGrid"
                  >
                  {postImages.map((postImg: PostImage) => {
                      return <Grid
                      className="collaborationGridItem"
                      data-test-id="listPostITemDiv"
                      style={{ position: "relative", display: "inline-block",cursor:"pointer" }}
                      onClick={(event)=> {
                        event.stopPropagation();
                        this.handleNavigationToPostDetails(profile.id, postImg.post_id)
                      }}
                      >
                        <div className="collaborationGridItemOutside">
                      {!postImg.confidential && (
                          <div className="collaborationGridItemInside" key={postImg.id}>
                          <img
                              data-test-id="postImg"
                              src={typeof postImg.image_url !== 'undefined' ? postImg.image_url : orangeBanner}
                              className="collabImg"
                          />
                          <div className="collabImgHover">{postImg.project_title}</div>
                          {this.renderSaveLikeShare(
                              postImg.is_like,
                              postImg.is_favourite,
                              postImg.id,
                              postImg.post_id,
                              profile.id
                          )}
                          </div>
                      )}
                      {postImg.confidential && (
                          <div className="collaborationGridItemInside" key={postImg.id}>
                          <img
                              data-test-id="ImgPostConfidential"
                              style={{ opacity: "0.2" }}
                              src={typeof postImg.image_url !== 'undefined' ? postImg.image_url :  orangeBanner}
                              className="collabImg"
                          />
                          <div className="restrictedDivMain">
                              <div className="restrictedDiv">
                              <NotInterestedIcon/>
                              <p className="confidentialText">Confidential</p>
                              </div>
                              <p className="confidentialPara">
                              You have to be a collaborator to view this
                              content.
                              </p>
                          </div>
                          </div>
                      )}
                      </div>
                    </Grid>
                  })}
                  </Carousel>
              </div>
          </StyledCarousel>
      </div>
        })}</div>
      }
    }
  }

  render() {
    // Customizable Area Start
    const {needLoadMoreBtn, isLoading, minRate, maxRate, selectedCountries, profileData, seniorityLevels, selectedSeniorityLevels, workTypes, selectedWorkTypes, selectedSkills, skills, selectedProfessions, professions} = this.state;
    return (
      <ThemeProvider theme={theme}>
        <HeaderNavbar 
         navigation={this.props.navigation} 
         data-test-id="headerNavbar" 
         />
        <StyledFindTalentCatalogue data-test-id="findCatalougeContainer">
          <div className="mainContainer">
          <Typography className="title" data-test-id="creativeTitle">Creatives{this.renderCount()}</Typography>
          <AdvanceSearch 
            selectedSeniorityLevels={selectedSeniorityLevels}
            seniorityLevels={seniorityLevels}
            selectedSkills={selectedSkills}
            skills={skills}
            workTypes={workTypes}
            selectedWorkTypes={selectedWorkTypes}
            selectedProfessions={selectedProfessions}
            selectedCountries={selectedCountries}
            professions={professions}
            onChangeSearchText={this.handleChangeSearchText}
            onMinRateChange={this.handleMinRateChange}
            onMaxRateChange={this.handleMaxRateChange}
            onClickClearAll={this.handleClickClearAll}
            onClickClearFilter={this.handleClickClearFilter}
            onChangeFilter={this.handleChangeFilter}
            minRate={minRate}
            maxRate={maxRate}
            onClearRateRange={this.handleClearRateRange}
          />
          <ShareLinkDialog
              isOpen={this.state.isShareModalOpen}
              onClose={this.handleCloseButton}
              onClickChatButton={this.handleClickChatButton}
              shareLink={this.state.shareLink}
              isChatSend={this.state.isChatSend}
           />       
            <ShareMenuDialog
              isOpen={this.state.isChatModalOpen}
              onClose={this.handleCloseButton}
              onClickBackButton={this.handleBackButton}
              profiles={profileData?.map((option:{attributes:{account_id:number, first_name: string, last_name: string, photo: string}}) => ({
                account_id:option?.attributes?.account_id,
                firstName: option?.attributes?.first_name,
                lastName: option?.attributes?.last_name,
                photo: option?.attributes?.photo,
              }))}
              shareData={this.state.shareData}
              chatMessage={this.state.chatMessage}
              onChangeChatMessage={this.handleChangeChatMessage}
              onShareDataChange={this.handleShareData}
              onClickSendChat={this.handleSendChatButton}
              data-test-id="ShareMenuDialogID"
            />  
            {this.renderMain()}
            {needLoadMoreBtn ? <LoadMoreDiv data-test-id="loadMore" onClick={() => {
                this.handleLoadMoreClick()
            }}>
                {isLoading ? <>Loading <CircularProgress className="isLoading"/></>: "Load More"}
            </LoadMoreDiv> : <></>}
          </div>
        </StyledFindTalentCatalogue>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const LoadMoreDiv = styled("div")({
  border: "1px solid rgba(0, 0, 0, 0.87)",
  borderRadius: "4px",
  padding: "16px",
  marginTop: "12px",
  textAlign: "center",
  cursor: "pointer",
  fontFamily: "Silika-Medium",
  "& .isLoading":{
      height: "16px !important",
      width: "16px !important",
      marginLeft: "16px",
      "& svg":{
          color: "rgba(0, 0, 0, 0.87)"
      }
  }
});
const StyledFindTalentCatalogue = styled("div")(({theme}: {theme: Theme}) => ({
  "& .mainContainer":{
    paddingTop: "113px",
    paddingBottom: "40px",
    width: "1280px",
    margin: "0 auto",
    "@media (max-width: 1280px)":{
      width: "calc(100vw - 30px)",
      margin: "0 auto",
      padding: "0px"
    },
    [theme.breakpoints.down("xs")]:{
      width: "100%",
      margin: "0px",
      padding: "0px"
    }
  },
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]:{
    width: "calc(100vw - 40px)",
    padding: "20px"
  },
  "& .title":{
    fontFamily: "Silika-Medium",
    fontSize: "32px",
    lineHeight: "40px",
    marginBottom: "50px"
  },
  "& .profilesContainer":{
    display: "flex",
    flexFlow: "column",
    marginBottom: "92px",
    [theme.breakpoints.down("sm")]:{
      width: "100%"
    },
    "& .profileContainer":{
        display: "flex",
        flexFlow: "column",
        border: "1px solid #dfdfdf",
        borderRadius: "1px",
        padding: "42px 23px 23px 23px",
        marginBottom: "40px",
        "&:last-child":{
          marginBottom: "0",
        },
        "@media (max-width: 768px)": {
          width: "calc(100% - 48px)",
          marginBottom: "20px",
          flexDirection: "column-reverse",
        },
        "@media (max-width: 519px)":{
          paddingBottom: "40px"
        },
        "@media (max-width: 399px)":{
          paddingBottom: "14px"
        }
    },
    "& .marginTop":{
      "@media (max-width: 959px)":{
        marginTop: "-70px"
      },
    },
    "& .profileInfoUpper":{
        width: "calc(100% - 158px)",
        display: "inline-flex",
        padding: "0 79px",
        "@media (max-width: 768px)": {
          display: "flex",
          flexFlow: "column",
          padding: "0px",
          width: "100%"
        },
    },
    "& .profileinfoDiv":{
        display: "flex",
        marginTop: "30px",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
          flexWrap: "wrap",
        },
      },
    "& .userProfileImgDiv":{
    width: "fit-content",
    height: "fit-content",
    "@media (max-width: 768px)":{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "-155px",
      zIndex: 9999
    },
    "@media (max-width: 699px)":{
      marginTop: "-140px",
    },
    "@media (max-width: 519px)":{
      marginTop: "-120px",
    },
    "@media (max-width: 399px)":{
      marginTop: "-100px",
    },
    "@media (max-width: 349px)":{
      marginTop: "-90px",
    }
    },
    "& .userProfileImg":{
        borderRadius: "100px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "189px",
        height: "189px",
        cursor: "pointer",
        "@media (max-width: 768px)":{
          width: "150px",
          height: "150px",
          border: "5px solid #fff",
        },
        "@media (max-width: 699px)":{
          width: "120px",
          height: "120px",
        },
        "@media (max-width: 519px)":{
          width: "100px",
          height: "100px",
          border: "3px solid #fff",
        },
        "@media (max-width: 399px)":{
          width: "80px",
          height: "80px",
          fontSize: "20px"
        },
        "@media (max-width: 349px)":{
          width: "60px",
          height: "60px",
        },
        "@media (max-width: 299px)":{
          width: "40px",
          height: "40px",
        },
        fontSize: "40px"
    },
    "& .profileDetailsDiv":{
        width: "78%",
        marginLeft: "47px",
        "@media (max-width: 768px)": {
          width: "100%",
          margin: "0px"
        }
    },
    "& .profileDetailHeader":{
      display: "inline-flex",
      justifyContent: "space-between",
      width: "100%",
      "@media (max-width: 768px)": {
        display:"flex",
        flexFlow: "column"
      }
    },
    "& .availabilityIcons":{
      display: "none",
      flexFlow: "column",
      "@media (max-width: 768px)": {
        flexFlow: "row",
        justifyContent: "center"
      },
      "& span":{
        borderRadius: "2px",
        fontFamily: "Silika-Medium",
        textAlign: "center",
        "@media (max-width: 768px)": {
          fontSize: "12px !important",
          lineHeight: "14.48px !important",
          padding: "2px 8px !important"
        },
      },
      "& .shortlist":{
        padding: "14px 15px",
        background: "#0D0C22",
        marginBottom: "3px",
        fontSize: "16px",
        lineHeight: "19.3px",
        color: "#fff",
        "@media (max-width: 768px)": {
          margin: "0 4px 0"
        },
      },
      "& .available":{
        padding: "4px 11px",
        background: "#00FFA7",
        fontSize: "12px",
        lineHeight: "14.48px",
        color: "#0D0C22",
        "@media (max-width: 768px)": {

        },
      }
    },
    "& .userNameMob": {
      "@media (max-width: 768px)": {
        "& p":{
          textAlign: "center"
        }
      }
    },
    "& .userName":{
        fontSize: "32px",
        fontFamily: "Silika-Medium",
        fontWeight: 500,
        lineHeight: "38.61px",
        cursor: "pointer"
    },
    "& .description":{
        fontSize: "14px",
        fontFamily: "Silika-Medium",
        fontWeight: 500,
        lineHeight: "19.69px",
        fontStyle: "normal",
        marginTop: "11px",
    },
    "& .detailsWrapper":{
        marginTop: "25px",
        width: "100%",
        marginBottom: "19px",
        "@media (max-width: 768px)": {
          margin: "12px 0"
        }
    },
    "& .detailsRow":{
        width: "100%",
        display: "inline-flex",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          "&:last-child":{
           flexFlow: "row"
          }
        }
    },
    "& img.actionIcons":{
      cursor: "pointer",
    },
    "& .actionIcons":{
        display: "inline-flex",
        paddingTop: "16px",
        "& svg":{
            marginRight: "17px",
            cursor: "pointer",
            "&:last-child":{
                marginRight: "0px"
            }
        },
        "& .isSaved":{

        }
    },
    "& .row1":{
        width: "24%",
        "@media (max-width: 768px)": {
          width: "fit-content"
        }
    },
    "& .row2":{
        width: "76%",
        "@media (max-width: 768px)": {
          width: "fit-content"
        }
    },
    "& .detail":{
        display: "inline-flex",
        alignItems: "center",
        padding: "13px 0 13px 22px",
        "@media (max-width: 768px)": {
          padding:"2px 0px"
        },
        "& svg":{
            height: "16.67px"
        }
    },
    "& .detailTitle": {
        fontSize: "12px",
        fontFamily: "Silika-Medium",
        fontWeight: 500,
        lineHeight: "140.625%",
        fontStyle: "normal",
        color: "#9B9B9D",
        alignSelf: "center",
    },
    "& .detailValue": {
        marginLeft: "10.33px",
        display: "inline-flex",
        "& p":{
            fontFamily: "Silika-Medium",
            fontSize: '12px',
            lineHeight: "140.625%",
            fontStyle: "normal",
            alignSelf: "center"
        }
    },
    "& .borderLine":{
        borderBottom: "1px solid #D9D9D9",
        height: "1px",
        width: "100%",
        display: "block",
        "@media (max-width: 768px)": {
          display: "none"
        }
    }
  },
  "& .emptyDiv":{
    height: "100%",
    width: "100%",
    paddingBottom: "92px"
  },
  "& .emptyContainer":{
    border: "1px solid #dfdfdf",
    borderRadius: "4px",
    height: "100%",
    width: "100%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]:{
      height: "auto",
      padding: "4px"
    },
    "& .p1":{
      fontSize: "32px",
      fontFamily: "Silika-Medium",
      lineHeight: "38.61px",
      marginBottom: "9px",
      [theme.breakpoints.down("sm")]:{
        fontSize: "24px",
        lineHeight: "19.2px",
      }
    },
    "& .p2":{
      fontSize: "16px",
      fontFamily: "Silika-Medium",
      lineHeight: "19.2px",
      color: "#9b9b9d",
      [theme.breakpoints.down("sm")]:{
        fontSize: "12px"
      }
    }
  }
}));

const ProfileAvatar = styled(Avatar)({
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  textAlign: "center",
  "& .MuiAvatar-img": {
    objectFit: "cover",
  }
});

const StyledCarousel = styled("div")(({theme}: {theme: Theme}) => ({
  maxWidth: "1140px",
  width: "100%",
  margin: "39px auto 0 auto",
  [theme.breakpoints.down("sm")]:{
    margin: "0 auto",
  },
  "& .carouselMain":{
    position: "relative",
    margin: "-12px",
    width: "calc(100% + 24px)",
    "@media (max-width: 768px)": {
      width: "auto"
    }
  },
  "@media (max-width: 768px)": {
    display: "contents !important",
    "& .carouselMain":{
    margin: "0px"
    }
  },
  "& .collaborationGridItem":{
    width: "100%",
    aspectRatio: "396/306",
    maxHeight: "306px"
  },
  "& .collaborationGridItem:hover .collaborationGridItemInside": {
    filter: "grayscale(0.3)",
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    transition: "opacity 0.3s ease",
  },
  "& .collaborationGridItem:hover .collabImgHover, .collaborationGridItem:hover .actionIcons, .collaborationGridItem:hover .removeCollab": {
    opacity: 1
  },
  "& .collaborationGridItem:hover .collabImg": {
    opacity: 0.5
  },
  "& .rec-slider-container":{
    margin: "0px !important"
  },
  "& .rec-item-wrapper":{
    height: "100%"
  },
  "& .collaborationGridItem, .collaborationGridItemInside, .collabImg":{
    height: "100%"
  },
  "& .collabImg, .restrictedDivMain, .rec-item-wrapper": {
    width: "100%"
  },
  "& .collaborationGridItemOutside":{
    padding: "0 13px",
    borderRadius: "4px",
    "& img":{
      borderRadius: "4px"
    },
    "@media (max-width: 768px)": {
      padding: "13px",
    },
    "@media (max-width:599px)": {
      padding: "6px",
    }
  },
  "& .collabImg":{
    objectFit: "cover",
    maxHeight: "306px"
  },
  "& .collaborationGridItemInside":{
    maxHeight: "306px",
    position: "relative"
  },
  "& .collabImgHover": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    fontFamily: "Silika-Medium",
    color: "#010101",
    fontSize: "20px",
    borderRadius: "4px",
    "@media (max-width: 824px)": {
      fontSize: "14px",
    },
  },
  "& .restrictedImg": {
    width: "24px",
    height: "24px",
  },
  "& .restrictedDiv": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg":{
      color: "#fff"
    }
  },
  "& .restrictedDivMain": {
    position: "absolute",
    top: "0%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    borderRadius: "4px"
  },
  "& .confidentialText": {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: 500,
    marginLeft: "4px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .confidentialPara": {
    color: "#FFFFFF",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    margin: 0,
    maxWidth: "220px",
    fontSize: "14px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .actionIcons": {
    display: "inline-flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "12px",
    opacity: 0,
    "& .actionButton": {
      height: "24px",
      width: "24px",
      pointer:"pointer",
      "@media (max-width: 802px)": {
        height: "18px",
        width: "18px",
      },
      "&:first-child": {
        marginRight: "12px"
      }
    },
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .actionButtonImg": {
    height: "22px",
    width: "22px",
    marginRight: "12px",
    cursor:"pointer",
    "@media (max-width: 802px)": {
      height: "18px",
      width: "18px",
    },
  },
  "& .customArrow": {
    position: "absolute",
    top: "45%",
    transform: "translateY(-50%)",
    zIndex: 1,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    }
  },
  "& .prevArrow": {
    left: "30px"
  },
  "& .nextArrow": {
    right: "30px"
  },
  "& .rec-swipable": {
    width: "100% !important"
  },
  "@media (max-width: 2000px)": {
    "& .carousel-item": {
      width: "33.33%",
    },
  },
  "@media (max-width: 480px)": {
    "& .carousel-item": {
      width: "100%",
    },
  },
}));

// Customizable Area End
