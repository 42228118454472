import _ from "lodash";

export const returnTruthyArray = (array: unknown[]) => {
    return array?.length ? array : []
}

export const returnTruthyString = (value: string | null | undefined) => {
    return value ? String(value) : ""
}
export function formatYearRange(yearFrom: string, yearTo: string) {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const [fromYear, fromMonth] = yearFrom.split('-').map(Number);
    const [toYear, toMonth] = yearTo.split('-').map(Number);

    const fromFormatted = `${months[fromMonth - 1]} ${fromYear}`;
    const toFormatted = `${months[toMonth - 1]} ${toYear}`;

    return `${fromFormatted} - ${toFormatted}`;
}

export function openInNewTab(url: string) {
    if (url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}

export const checkCondition = (condition: Boolean, trueValue: unknown, falseValue: unknown) => {
    return condition ? trueValue : falseValue
}

export function isObjectEmpty(obj: unknown): boolean {
    if (typeof obj === 'object' && obj !== null) {
        return _.every(obj, value => _.isNil(value) || value === '');
    }
    return false;
}

export function concatArrayOfstring (...arr: string[]){
    return [...arr].filter(v => (v !== "") && (typeof v == "string")).join("  |  ");
}

export function isImageOrVideo(url: string): 'image' | 'video' | 'unknown' {
    const imageExtensions: string[] = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
    const videoExtensions: string[] = ['mp4', 'avi', 'webm', 'mkv', 'mov'];
  
    const extension: string = url?.split('.')?.pop()?.toLowerCase() || '';
  
    if (imageExtensions.includes(extension)) {
      return 'image';
    } else if (videoExtensions.includes(extension)) {
      return 'video';
    } else {
      return 'unknown';
    }
}
