import React, { Component } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Switch, Typography, styled, withStyles } from "@material-ui/core";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
const imgLogo = require("./app_logo_white.svg");
const linkedInLogo = require("./linkedIn.svg");
const instagramLogo = require("./instagram.svg");
const facebookLogo = require("./facebook.svg");
import ClearIcon from '@material-ui/icons/Clear';

interface Props {
    navigation?: {};
}

interface S {
    isOpen: boolean,
    isCookieOpen: boolean
}

const StyledContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    "& *":{
        fontFamily: "Silika-Medium",
    },
    "& .footerContainer": {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "34px",
        marginLeft: "34px",
        color: "white",
        marginTop: "58px",
    },
    "& .contentContainer": {
        display: "flex",
    },
    "& .logoForge": {
        color: "white",
        width: "19.41px",
        height: "25.67px",
        cursor:"pointer !important"
    },
    "& .footerTextTitles": {
        fontWeight: 500,
        fontSize: "15px",
        lineHeight: "18.1px",
        marginTop: "5px",
        cursor:"pointer !important",
        "@media(max-width:720px)": {
            marginTop: "10px"
        },
        "@media(max-width:670px)": {
            width:"100%"
        },
    },
    "& .footerTextTitleItems": {
        fontWeight: 300,
        fontSize: "12px",
        lineHeight: "14.47px",
        marginTop: "5px",
        fontFamily:"Silika-Light",
        cursor:"pointer !important"
    },
    "& .logoSocial": {
        height: "24px",
        width: "24px",
        marginLeft: "auto",
        cursor:"pointer !important",
        "@media(max-width:720px)": {
            marginLeft: "0px",
            marginTop: "10px",
        },
    },
    "& .logoSocialDiv": {
        justifyContent: "flex-end",
        display: "flex",
        "@media(max-width:1096px)": {
            marginLeft:"38px"
        },
        "@media(max-width:752px)": {
            marginLeft:"0px"
        },
        "@media(max-width:720px)": {
            marginTop: "10px",
            justifyContent: "space-between",
            borderTop: "2px solid white"
        },

    },
    "& .blockOfFooterSection":{
        display:"flex",
        marginLeft:"32.56px",
        gap:"58px",
        flexWrap:"wrap",
        "@media(max-width:750px)": {
           width:"100%"
        },
        "@media(max-width:746px)": {
            gap:"40px",
        },
        "@media(max-width:672px)": {
            gap:"10px",
            marginLeft:"0px"
        },
    },
    "& .blockOfFooter":{
        "@media(max-width:672px)": {
           width:"100% !important"
        },
    }
});

const StyledFootContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "50px",
    marginBottom: "58px",
    marginRight: "34px",
    marginLeft: "80px",
    color: "white",
    "@media(max-width:672px)": {
        marginLeft: "32px",
     },
    "& *":{
        fontFamily: "Silika-Medium",
    },
    "& .footer": {
        color: "white"
    },
    "& .footerTextSubTitle": {
        fontWeight: 300,
        fontSize: "15px",
        lineHeight: "18.08px",
        opacity: "0.8",
        marginLeft: "auto",
    },
    "& .footerItemsEnd": {
        display: "flex",
        gap:"65px",
        flexWrap: "wrap",
        "@media(max-width:672px)": {
            gap: "0px !important",
         },
    },
    "& .footerTextTitles": {
        fontFamily:"Silika-Light",
        cursor:"pointer !important",
        "@media(max-width:720px)": {
            marginTop: "10px",
            width: "88vw",
            marginLeft: "0px",
        },
    },
    "& .footerLastTitle":{
        fontSize:"15px !important"
    },
   
    
})

const DialogActionWrapper = styled(DialogActions)({
    display: "flex",
    paddingRight: "40px",
    paddingTop: "16px",
    paddingBottom: "24px",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "@media(max-width:624px)": {
        justifyContent: "center"
    },
    "& .copyBtnStyle": {
        border: "0.5px solid #DFDFDF",
        padding: "16px 0px",
        boxShadow: "0px 4px 8px 5px rgba(0, 0, 0, 0.06)",
    },
    "& .SendBtnStyle": {
        marginLeft: "auto",
        padding: "24px",
        backgroundColor: "#0D0C22",
        color: "#fff",
        height: "50px",
        width: "120px",
        marginRight: "16px",
    },
    "& .opacity-05": {
        opacity: 0.2
    },
});

const StyledPrivacyContainer = styled(Dialog)({
    "& .dialogueHeader": {
        background: "black !important",
        color: "white",
        height: "60px",
        paddingRight: "40px",
        paddingLeft: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px"
    },
    "& .MuiDialogTitle-root": {
        padding: "0px",
        width: "100% !important"
    },
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "75vw !important",
        width: "75vw !important",
        maxHeight: "auto !important",
        height: "auto !important"
    },
    "& .dialogueHeaderContainer": {
        display: "flex",
    },
    "& .dialogContent": {
        paddingTop: "24px",
        paddingLeft: "40px",
        paddingRight: "40px"
    },
    "& .privacyTitle": {
        fontSize: "16px",
        fontWeight: "500",
        color: "#242121"
    },
    "& .privacySubTitle": {
        fontSize: "12px",
        fontWeight: "300",
        marginTop: "8px",
        opacity: "0.8"
    },
    "& .privacyDescription": {
        fontSize: "16px",
        fontWeight: "300",
        marginTop: "20px",
        opacity: "0.8"
    },
    "& .contentSection": {
        marginTop: "20px",
    },
    "& .headingSection": {
        fontSize: "16px",
        fontWeight: "500",
        color: "#242121",
        paddingLeft: "8px"
    },
    "& .customizedBtn": {
        textTransform: "none",
        border: "1px solid #010101",
        width: "116px",
        height: "48px",
        "@media(max-width:624px)": {
            width: "50%",
        },
    },
    "& .rejectBtn": {
        backgroundColor: "#0D0C22",
        color: "white",
        width: "116px",
        height: "48px",
        textTransform: "none",
        "@media(max-width:624px)": {
            width: "50%",
            marginTop: "16px",
            marginLeft: "0px",
            marginRight: "16px",
        },
    }
})

const StyledCookiePolicyContainer = styled(Dialog)({
    fontFamily: "Silika-Medium",
    "& .dialogueHeader": {
        justifyContent: "space-between",
        background: "black !important",
        fontSize: "16px",
        color: "white",
        height: "60px",
        paddingLeft: "40px",
        display: "flex",
        alignItems: "center",
        paddingRight: "40px",

    },
    "& .MuiDialogTitle-root": {
        width: "100% !important",
        padding: "0px",
    },
    "& .MuiDialog-paperWidthSm": {
        width: "75vw !important",
        maxWidth: "75vw !important",
        maxHeight: "auto !important",
        height: "auto !important"
    },
    "& .dialogueHeaderContainer": {
        display: "flex",
    },
    "& .dialogContent": {
        paddingTop: "24px",
        paddingLeft: "40px",
        paddingRight: "40px"
    },
    "& .privacyDescription": {
        fontSize: "16px",
        fontWeight: "300",
        marginTop: "20px",
        opacity: "0.8"
    },
    "& .cookieBlockContainer": {
        display: "flex",
        justifyContent: "space-between",
        paddingRight:"20px",
        marginTop:"20px"
    },
    "& .headingCookie": {
        fontWeight: "500 !important",
        fontSize: "16px",
        color:"#000000",
        fontFamily: "Silika-Medium",
    },
    "& .cookieDescription": {
        fontWeight: "300 !important",
        fontSize: "14px",
        fontFamily:"Silika-Light !important",
        marginTop:"8px"
    },
    "& .switch":{
        paddingRight:"20px"
    },
    "& .cookiePolicyDiv":{
        marginTop:"56px"
    },
    "& .cookieDescriptionHead":{
         fontFamily:"Silika-Light !important",
         opacity:"1"
    },
    "& .onOffText":{
        marginLeft:"8px",
        fontFamily:"Silka !important",
        fontWeight:500,
        fontSize:"16px",
        color:"#0D0C22"
    },
    "& .rejectBtn": {
        backgroundColor: "#0D0C22",
        color: "white",
        width: "116px",
        height: "48px",
        fontFamily:"Silika-Medium !important",
        textTransform: "none",
        "@media(max-width:624px)": {
            width: "50%",
            marginTop: "16px",
            marginLeft: "0px",
            marginRight: "16px",
        },
         
    }

})

const DialogActionCookieWrapper = styled(DialogActions)({
    display: "flex",
    paddingTop: "16px",
    paddingBottom: "24px",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    "@media(max-width:624px)": {
        justifyContent: "center"
    },
    "& .copyBtnStyle": {
        border: "0.5px solid #DFDFDF",
        padding: "16px 0px",
        boxShadow: "0px 4px 8px 5px rgba(0, 0, 0, 0.06)",
    },
    "& .SendBtnStyle": {
        marginLeft: "auto",
        padding: "24px",
        backgroundColor: "#0D0C22",
        color: "#fff",
        height: "50px",
        width: "120px",
        marginRight: "16px",
    },
    "& .opacity-05": {
        opacity: 0.2
    },
});

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 48,
        padding: 0,
        height: 28,
    },
    switchBase: {
        padding: 1,
        top: 1,
        left: 1,
        '&$checked': {
            color: theme.palette.common.white,
            transform: 'translateX(17px)',
            '& + $track': {
                opacity: 1,
                backgroundColor: "#fff",
                border: 'none',
            },
        },
        "&.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: '#010101 !important',
            opacity: 1
        },
        '&$focusVisible $thumb': {
            border: '6px solid #fff',
            color: '#fff',
        },
        '& #swith': {
            marginTop: "1px",
        },
        "& .MuiSwitch-root-45": {
            marginRight: '16px !important'
        },
    },
    track: {
        borderRadius: 29 / 2,
        opacity: 1,
        border: `0px solid ${theme.palette.grey[400]}`,
        backgroundColor: '#DFDFDF',
        transition: theme.transitions.create(['background-color', 'border']),
    },
    thumb: {
        height: 24,
        width: 24,
        color: '#fff'
    }
}))(Switch);

const StyledButton = styled(Button)({
    "& .MuiButton-label": {
      fontSize:"16px !important"
    }
});

export class LandingFooter extends Component<Props, S> {
    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            isCookieOpen: false
        };
    }

    handleNavigation = (screenName: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        runEngine.sendMessage(message.id, message);
    };

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    handleOpenModal = () => {
        this.setState({ isOpen: true })
    }

    handleOpenCookieModal = () => {
        this.setState({ isCookieOpen: true })
    }

    handleCookieClose = () => {
        this.setState({ isCookieOpen: false })
    }

    scrollToSection = (sectionIdName:string) => {
        const section = document.getElementById(sectionIdName);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    privacyPolicyDialoge = () => {
        return (
            <StyledPrivacyContainer
                open={this.state.isOpen}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                keepMounted
            >
                <DialogTitle id="alert-dialog-slide-title">
                    <Grid container className="dialogueHeaderContainer">
                        <Grid item xs={12} className="dialogueHeader">
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Box>
                                    Privacy and cookie policy
                                </Box>
                                <Box>
                                    <IconButton style={{ color: "white" }} onClick={this.handleClose} data-testId="clsoePopupModelId">
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="dialogContent">
                    <Typography className="privacyTitle">General Site Usage</Typography>
                    <Typography className="privacySubTitle">Last revised: December 2022</Typography>
                    <Typography className="privacyDescription">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer id erat auctor, rutrum ex id, tincidunt tellus, Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus.
                    </Typography>
                    <Box className="contentSection">
                        <Typography className="headingSection">1. Your Agreement</Typography>
                        <Typography className="privacyDescription">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus, Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id,
                            tincidunt tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                        </Typography>
                        <Typography className="privacyDescription">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus, Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id,
                            tincidunt tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                        </Typography>

                    </Box>
                    <Box className="contentSection">
                        <Typography className="headingSection">2. Your Agreement</Typography>
                        <Typography className="privacyDescription">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus, Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id,
                            tincidunt tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                        </Typography>
                        <Typography className="privacyDescription">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Integer id erat auctor, rutrum ex id, tincidunt tellus, Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id,
                            tincidunt tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor,
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActionWrapper>
                    <Button className="customizedBtn" style={{ marginRight: "16px" }}>
                        Customize
                    </Button>
                    <Button className="rejectBtn" style={{ marginRight: "16px" }} onClick={this.handleClose}>
                        Reject All
                    </Button>
                    <Button className="rejectBtn acceptAllBtn" onClick={this.handleClose}>
                        Accept All
                    </Button>
                </DialogActionWrapper>
            </StyledPrivacyContainer>
        );
    }

    cookiePolicyDialoge = () => {
        return (
            <StyledCookiePolicyContainer
                open={this.state.isCookieOpen}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                keepMounted
            >
                <DialogTitle id="alert-dialog-slide-title">
                    <Grid container className="dialogueHeaderContainer">
                        <Grid item xs={12} className="dialogueHeader">
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Box style={{fontFamily:"Silika-Medium",fontSize:"16px", fontWeight: 500}}>
                                    Cookie policy
                                </Box>
                                <Box>
                                    <IconButton style={{ color: "white" }} onClick={this.handleCookieClose} data-testId="clsoePopupModelId">
                                        <ClearIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="dialogContent">
                    <Typography className="privacyDescription cookieDescriptionHead">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer id erat auctor, rutrum ex id, tincidunt tellus, Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus.
                    </Typography>
                    <Grid className="cookiePolicyDiv">
                        <Box className="cookieBlockContainer">
                            <Box>
                                <Typography className="headingCookie">Necessary</Typography>
                                <Typography className="cookieDescription">Required for our website to work. The setting cannot be changed</Typography>
                            </Box>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <IOSSwitch checked/>
                                <Typography variant="body2" className="onOffText">On</Typography>
                            </Box>
                        </Box>
                        <Box className="cookieBlockContainer">
                            <Box>
                                <Typography className="headingCookie">Functional</Typography>
                                <Typography className="cookieDescription">Understands how you use our website and tailors the content to your needs.</Typography>
                            </Box>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <IOSSwitch />
                                <Typography variant="body2" className="onOffText">Off</Typography>
                            </Box>
                        </Box>
                        <Box className="cookieBlockContainer">
                            <Box  className="">
                                <Typography className="headingCookie">Necessary</Typography>
                                <Typography className="cookieDescription">Required for our website to work. The setting cannot be changed</Typography>
                            </Box>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <IOSSwitch checked/>
                                <Typography variant="body2" className="onOffText">On</Typography>
                            </Box>
                        </Box>
                        <Box className="cookieBlockContainer">
                            <Box>
                                <Typography className="headingCookie">Functional</Typography>
                                <Typography className="cookieDescription">Understands how you use our website and tailors the content to your needs.</Typography>
                            </Box>
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <IOSSwitch />
                                <Typography variant="body2" className="onOffText">Off</Typography>
                            </Box>
                        </Box>
                        <DialogActionCookieWrapper>
                            <StyledButton className="rejectBtn" style={{ marginRight: "16px" }} onClick={this.handleCookieClose}>
                                Reject All
                            </StyledButton>
                            <StyledButton className="rejectBtn acceptAllBtn" onClick={this.handleCookieClose}>
                                Accept All
                            </StyledButton>
                        </DialogActionCookieWrapper>
                    </Grid>
                </DialogContent>

            </StyledCookiePolicyContainer>
        )
    }

    render() {
        return (
            <div style={{ backgroundColor: "#383838" }}>
                <StyledContainer>
                    <Grid container className="footerContainer">
                        <Grid item xl={7} lg={10} md={11} sm={12}>
                            <Grid container className="contentContainer" style={{maxWidth:"818px",display:"flex"}}> 
                                <img src={imgLogo} alt="logo"  onClick={() => this.handleNavigation("LandingPageWeb")} className="logoForge" />
                                <Box className="blockOfFooterSection">
                                <Grid item className="blockOfFooter">
                                    <Typography className="footerTextTitles" onClick={() => this.handleNavigation("LandingPage5Web")}>Design Talent</Typography>
                                    <Typography className="footerTextTitleItems">
                                        <span className="footerTextTitleItems"onClick={() => this.scrollToSection('Design Research')}>Design Research</span><br />
                                        <span className="footerTextTitleItems"onClick={() => this.scrollToSection('Exterior Design')}>Exterior Design</span><br />
                                        <span className="footerTextTitleItems"onClick={() => this.scrollToSection('Interior Design')}>Interior Design</span><br />
                                        <span className="footerTextTitleItems"onClick={() => this.scrollToSection('CMF Design')}>CMF Design</span><br />
                                        <span className="footerTextTitleItems"onClick={() => this.scrollToSection('UX/UI Design')}>UX/UI Design</span>
                                    </Typography>
                                </Grid> 
                                <Grid item className="blockOfFooter">
                                    <Typography className="footerTextTitles" onClick={() => this.handleNavigation("LandingPage7Web")}>Technical Talent</Typography>
                                    <Typography className="footerTextTitleItems" onClick={() => this.scrollToSection('Creative Modelling')}>Creative Modelling </Typography>
                                    <span className="footerTextTitleItems"  onClick={() => this.scrollToSection('CAD Research')}>CAD Research</span><br />
                                    <span className="footerTextTitleItems" onClick={() => this.scrollToSection('Class A surfacing')}> Class A surfacing</span><br />
                                    <span className="footerTextTitleItems" onClick={() => this.scrollToSection('Product Management')}>  Product Management</span><br />
                                    <span className="footerTextTitleItems" onClick={() => this.scrollToSection('Project Management')}> Project Management</span><br />
                                    <span className="footerTextTitleItems"onClick={() => this.scrollToSection('Studio Engineering')}> Studio Engineering</span>
                                </Grid>
                                <Typography className="footerTextTitles" onClick={() => this.handleNavigation("LandingPageWeb6")}>Gallery</Typography>
                                <Typography className="footerTextTitles" onClick={() => this.handleNavigation("LandingPage3Web")}>Pricing</Typography>
                                <Typography className="footerTextTitles"onClick={() => this.handleNavigation("SupportPage")}>Support</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xl={1} lg={2} sm={2} className="logoSocialDiv" style={{maxWidth: "86px"}}>
                            <img src={facebookLogo} alt="logo" className="logoSocial" />
                            <img src={linkedInLogo} alt="logo" className="logoSocial" />
                            <img src={instagramLogo} alt="logo" className="logoSocial" />
                        </Grid>
                        
                    </Grid>
                </StyledContainer>
                <StyledFootContainer className="footer">
                    <Grid container>
                    <Grid item xl={6} lg={7} className="footerItemsEnd">
                            <Typography className="footerTextTitles footerLastTitle">© 2024 Forge Design Consultancy Ltd.</Typography>
                            <Typography className="footerTextTitles  footerLastTitle">Terms</Typography>
                            <Typography className="footerTextTitles  footerLastTitle" onClick={this.handleOpenModal}>Privacy</Typography>
                            <Typography className="footerTextTitles  footerLastTitle" onClick={this.handleOpenCookieModal}>Cookies</Typography>
                        </Grid>
                    </Grid>
                    <Fade in={this.state.isOpen}>
                    {this.privacyPolicyDialoge()}
                    </Fade>
                    <Fade in={this.state.isCookieOpen}>
                    {this.cookiePolicyDialoge()}
                    </Fade>
                </StyledFootContainer>
            </div>
        );
    }
}
