import React from "react";
import { Paper,OutlinedInput, TextField, styled, Theme, InputAdornment, Typography, Select, MenuItem } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { poundIcon } from "../../../customisableuserprofiles/src/assets"
import { Autocomplete } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Country }  from 'country-state-city';
import CloseIcon from '@material-ui/icons/Close';

interface Props{
    onChangeSearchText: (event: React.ChangeEvent<HTMLInputElement>) => void;
    seniorityLevels: string[];
    selectedSeniorityLevels: string[];
    workTypes: string[];
    selectedCountries: string[];
    selectedWorkTypes: string[];
    skills: string[];
    selectedSkills: string[];
    professions: string[];
    selectedProfessions: string[];
    onMinRateChange: (value: number | undefined) => void;
    onMaxRateChange: (value: number | undefined) => void;
    onClickClearAll: (refetch: boolean) => void;
    onClickClearFilter: (values: string[], type: string) => void;
    onChangeFilter: (values: string[], fieldName: string) => void;
    minRate: number | undefined;
    maxRate: number | undefined;
    onClearRateRange: () => void
}

const StyledTextField = styled(TextField)(({theme}:{theme: Theme}) => ({
    [theme.breakpoints.down('md')]: {
        margin: "0",
        width: "100% !important",
    },
    "& .textfield":{
        height: "48px",
        [theme.breakpoints.down('md')]: {
            fontSize: "16px",
            "& input":{
                width: "calc(100% - 16px) !important"
            },  
        },
        
    },
    "& input":{
        cursor: "pointer"
    },
    "& input::placeholder":{
        fontFamily: "Silika-Medium"
    }
}));

const StyledSelect = styled(Select)({
    width: "100%",
    "& .MuiSelect-root": {
      display: "flex",
      alignItems: "center"
    },
    "& .MuiInputBase-input": {
      fontFamily: "Silika-Medium",
      width: "100% !important",
      height: "48px !important",
      fontSize: "16px",
    },
  });

const MenuItemFont = styled(MenuItem)({
    fontFamily: "Silika-Medium",
    fontSize: "14px",
    color: "#0D0C22",
    padding: "7px 16px !important",
    "&:hover":{
        background: "#dfdfdf"
    },
    "&.Mui-selected": {
        background: "#010101",
        color: "#fff",
        "&:hover":{
            background: "#dfdfdf",
            color: "#010101"
        },
    }
});

const StyledPaper = styled("div")(({theme}: {theme: Theme}) => ({
    boxShadow: "0px 8px 32px 0px #0000000F",
    border: "1px solid #dfdfdf",
    maxHeight: 35*8,
    "& .MuiList-root":{
        padding: "5px 4px",
        [theme.breakpoints.down("sm")]:{
            padding: "2px",
        },
        "& li":{
            marginBottom: "2px",
            borderRadius: "2px"
        }
    }
  }));

const StyledAdvanceSearch = styled("div")(({theme}: {theme: Theme}) => ({
    "& *":{
        fontFamily: "Silika-Medium"
    },
    "& .filtersTitle":{
        margin: "16px 0"
    },
    "& .filterContainer":{
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap",
        alignItems: "end",
        justifyContent: "space-between",
        marginBottom: "51px",
        "& .startEndornment":{
            marginRight: "11px"
        },
        "& input":{
            cursor: "pointer"
        },
        "& input::placeholder":{
            color: "#010101 !important",
            opacity: 1
        },
        "@media (max-width: 1248px)": {
            justifyContent: "flex-start",
            "& > div":{
                margin: "0 8px 8px 0"
            }
        },
        "& .MuiInputBase-root":{
            borderRadius: "2px",
            height: "48px"
        },
        "& .seniorityLevel":{
            width: "118px",
            [theme.breakpoints.down("sm")]:{
                width: "140px"
            },
         
        },
        "& .workType":{
            width: "168px",
            [theme.breakpoints.down("sm")]:{
                width: "140px"
            }
        },
        "& .professionType":{
            width: "236px",
            [theme.breakpoints.down("sm")]:{
                width: "140px"
            }
        },
        "& .skills":{
            width: "218px",
            [theme.breakpoints.down("sm")]:{
                width: "140px"
            }
        },
        "& .country":{
            width: "219px",
            [theme.breakpoints.down("sm")]:{
                width: "140px"
            }
        },
        "& .rate":{
            width: "121px",
            [theme.breakpoints.down("sm")]:{
                width: "140px"
            }
        },
        "& .dash":{
            margin: "auto 5.15px"
        },
    },
    "& .borderLine":{
        borderBottom: "1px solid #D9D9D9",
        height: "1px",
        width: "100%",
        display: "block",
        [theme.breakpoints.down("sm")]:{
          display: "none"
        }
    },
    "& .selectedFilters":{
        display: "flex",
        flexFlow:"row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "11px",
        marginBottom: "63px",
       
    },
    "& .filters":{
        display: "inline-flex",
        "& .filter":{
            border: "0.5px solid #dfdfdf",
            borderRadius: "2px",
            display: "flex",
            alignItems: "center",
            padding: "4px 6px",
            marginRight: "10px",
            cursor: "pointer",
            "& svg": {
                height: "16px",
                width: "16px",
                marginLeft: "4px"
            }
        }
    },
    "& .clearFilters":{
        textDecoration: "underline",
        height: "20px",
        cursor: "pointer"
    },
    [theme.breakpoints.down("sm")]:{
        width: "calc(100% - 42px)"
    },
    [theme.breakpoints.down("sm")]:{
        width: "100%"
    }
}));

const StyledAutoCompletePaper = styled(Paper)(({theme}:{theme: Theme}) => ({
    boxShadow: "0px 8px 32px 0px #0000000F",
    border: "1px solid #dfdfdf",
    overflow: "hidden",
    "& .MuiAutocomplete-option[data-focus='true']": {
        backgroundColor: "#dfdfdf",
      },
      "& .MuiAutocomplete-option[aria-selected='true']": {
        backgroundColor: "#010101",
        color: "#fff"
      },
      "& .MuiAutocomplete-listbox":{
        padding: "5px 4px",
        maxHeight: 36*8,
        overflow: "auto",
        [theme.breakpoints.down("sm")]:{
            padding: "2px",
        },
        "& li":{
            marginBottom: "2px",
            borderRadius: "2px"
        }
      }
}));

class AdvanceSearch extends React.Component<Props>{
    constructor(props: Props){
        super(props)
    }

    conditionRender=(placeholder:string)=>{
        return <span>{placeholder}</span>
    }

    renderFilters =() => {
        const {minRate, maxRate, selectedCountries, selectedSeniorityLevels, selectedWorkTypes, selectedSkills, selectedProfessions} = this.props;
        
        let isFilterEnabled = false;

        if(selectedSkills.length > 0 || selectedProfessions.length > 0 || selectedSeniorityLevels.length > 0 || selectedWorkTypes.length > 0 || selectedCountries.length > 0 || this.checkRate()){
            isFilterEnabled = true;
        }

        return  isFilterEnabled ? (
            <>
            <span className="borderLine" />
            <div className="selectedFilters">
                <div className="filters">
                    {selectedSkills.map(skill => <div onClick={() => this.handleClearFilter("selectedSkills", selectedSkills, skill)} className="filter" data-test-id="skillFilter"><span>{skill}</span><CloseIcon/></div>)}
                    {selectedProfessions.map(profession => <div onClick={() => this.handleClearFilter("selectedProfessions", selectedProfessions, profession)} className="filter"><span>{profession}</span><CloseIcon/></div>)}
                    {selectedSeniorityLevels.map(seniorityLevel => <div onClick={() => this.handleClearFilter("selectedSeniorityLevels", selectedSeniorityLevels, seniorityLevel)} className="filter"><span>{seniorityLevel}</span><CloseIcon/></div>)}
                    {selectedWorkTypes.map(workType => <div onClick={() => this.handleClearFilter("selectedWorkTypes", selectedWorkTypes, workType)} className="filter"><span>{workType}</span><CloseIcon/></div>)}
                    {selectedCountries.map(country => <div onClick={() => this.handleClearFilter("selectedCountries", selectedCountries, country)} className="filter"><span>{country}</span><CloseIcon/></div>)}
                    {this.checkRate() ?<div onClick={() => this.handleClearFilter("rateRange")} className="filter" data-test-id="rateRangeFilter"><span>£{minRate}-{maxRate}</span><CloseIcon/></div>: <></>}
                </div>
                <span className="clearFilters" onClick={() => this.props.onClickClearAll(true)}>
                      Clear all filters
                </span>
            </div>
            </>
        ): <></>

    }

    handleClearFilter = (type: string, arr?: string[], value?: string) => {
        const types = ["selectedSkills", "selectedProfessions", "selectedSeniorityLevels", "selectedWorkTypes", "selectedCountries"];
        if(types.includes(type) && arr && arr.length > 0){
            this.props.onClickClearFilter([...arr].filter(x => x !== value), type);
        }else{
            this.props.onClearRateRange();
        }
    }

    checkRate = () => {
        const {minRate, maxRate} = this.props;
        if((minRate !== undefined) && (maxRate !== undefined) && (minRate < maxRate)){
            return true;
        }
        return false;
    }

    render() {
        const countries = Country.getAllCountries().map(state => ({ value: state.isoCode, label: state.name }));
        const {minRate, maxRate, selectedCountries, seniorityLevels, selectedSeniorityLevels, workTypes, selectedWorkTypes, selectedSkills, skills, selectedProfessions, professions} = this.props;
        return (
            <StyledAdvanceSearch>
                <StyledTextField 
                fullWidth
                variant="outlined"
                type="text"
                data-test-id="searchFav"
                onChange={this.props.onChangeSearchText}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                        ),
                    className: "textfield"
                }}
                placeholder="Name, Company, Position"
            />
            <Typography className="filtersTitle">
                Filters:
            </Typography>
            <div className="filterContainer">
                <StyledSelect
                    className="seniorityLevel"
                    data-test-id='seniorityLevel'
                    IconComponent={KeyboardArrowDownIcon}
                    value={selectedSeniorityLevels}
                    placeholder="Seniority"
                    multiple
                    displayEmpty
                    renderValue={() => this.conditionRender("Seniority")}
                    disableUnderline
                    name='seniorityLevel'
                    variant="outlined"
                    onChange={(event) => this.props.onChangeFilter(event.target.value as string[], "selectedSeniorityLevels")}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        MenuListProps:{
                            className:"seniorityDropdown"
                        },
                        PaperProps:{component: StyledPaper}
                    }}
                    
                    >
                    {seniorityLevels.map((menuItem) => <MenuItemFont key={menuItem} value={menuItem}>{menuItem}</MenuItemFont>)}
                </StyledSelect>
                <StyledSelect
                    data-test-id='workType'
                    className="workType"
                    IconComponent={KeyboardArrowDownIcon}
                    value={selectedWorkTypes}
                    multiple
                    displayEmpty
                    renderValue={() => this.conditionRender("Work Type")}
                    disableUnderline
                    name='workType'
                    variant="outlined"
                    placeholder="Work type"
                    onChange={(event) => this.props.onChangeFilter(event.target.value as string[], "selectedWorkTypes")}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        MenuListProps:{
                            className:"workTypeDropdown"
                        },
                        PaperProps:{component: StyledPaper}
                    }}
                    >
                    {workTypes.map((menuItem) => <MenuItemFont key={menuItem} value={menuItem}>{menuItem}</MenuItemFont>)}
                </StyledSelect>
                <Autocomplete
                    className="professionType"
                    data-test-id="professionType"
                    openOnFocus={false}
                    value={selectedProfessions}
                    multiple
                    options={professions}
                    PaperComponent={StyledAutoCompletePaper}
                    disableCloseOnSelect
                    renderOption={(option) => <div style={{fontFamily: "Silika-Medium"}}>{option}</div>}
                    renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps , startAdornment: <SearchIcon className="startEndornment"/>}} variant="outlined"  placeholder="Profession" />}
                    onChange={(_, newValue) => this.props.onChangeFilter(newValue, "selectedProfessions")}
                />
                <Autocomplete
                    className="skills"
                    data-test-id="skills"
                    openOnFocus={false}
                    value={selectedSkills}
                    multiple
                    options={skills}
                    disableCloseOnSelect
                    PaperComponent={StyledAutoCompletePaper}
                    renderOption={(option) => <div style={{fontFamily: "Silika-Medium"}}>{option}</div>}
                    renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps , startAdornment: <SearchIcon className="startEndornment"/>}} variant="outlined"  placeholder="Skills" />}
                    onChange={(_, newValue) => this.props.onChangeFilter(newValue as string[], "selectedSkills")}
                />
                <StyledSelect
                    data-test-id='country'
                    className="country"
                    IconComponent={KeyboardArrowDownIcon}
                    value={selectedCountries}
                    renderValue={() => this.conditionRender("Country")}
                    disableUnderline
                    multiple
                    displayEmpty
                    name='country'
                    variant="outlined"
                    onChange={(event) => this.props.onChangeFilter(event.target.value as string[], "selectedCountries")}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        MenuListProps:{
                            className:"countryDropdown"
                        },
                        PaperProps:{component: StyledPaper}
                    }}
                    >
                    {countries.map((menuItem) => <MenuItemFont key={menuItem.value} value={menuItem.label}>{menuItem.label}</MenuItemFont>)}
                </StyledSelect>
                <div>
                <OutlinedInput
                    placeholder="Min Rate"
                    className="rate"
                    value={minRate ?? ""}
                    data-test-id="minRate"
                    startAdornment={<InputAdornment position="start">
                        <img src={poundIcon} style={{
                            height: "14px"
                        }} /></InputAdornment>}
                    onChange={(event) => this.props.onMinRateChange(parseInt(event.target.value))}
                    inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                      }}
                />
                <span className="dash">-</span>
                 <OutlinedInput
                    placeholder="Max Rate"
                    className="rate"
                    value={maxRate ?? ""}
                    data-test-id="maxRate"
                    startAdornment={<InputAdornment position="start">
                        <img src={poundIcon} style={{
                            height: "14px",

                        }}/></InputAdornment>}
                    onChange={(event) => this.props.onMaxRateChange(parseInt(event.target.value))}
                    inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                      }}
                />
                </div>
            </div>
                {this.renderFilters()}
            </StyledAdvanceSearch>
        )
    }
}

export default AdvanceSearch;