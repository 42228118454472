import React from "react";
import { Grid, styled, Theme, Typography, Box, Avatar, Button } from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NoFavourites from "./NoFavourites";
import { FavouritePeople } from "../types";
import { returnTruthyString, isImageOrVideo } from "../../../../components/src/HelperUtils";
interface Props {
    favouritesPeople: FavouritePeople[],
    windowWidth: number
}

const StyledPeopleContainer = styled("div")(({ theme }: { theme: Theme }) => ({
    marginTop: "95px",
    [theme.breakpoints.down('sm')]: {
        marginTop: "24px",
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "center"
    },
    "& .peopleListItem": {
        border: "1px solid #DFDFDF",
        padding: "6px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        marginBottom: "24px",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100% - 40px)"
        }
    },
    "& .card": {
        border: "1px solid #DFDFDF",
        borderRadius: "4px",
        marginBottom: "30px",
        display: "inline-flex",
        width: "calc(100% - 40px)",
        padding: "24px 20px",
        minHeight: "180px",
        justifyContent: "space-between"
    },
    "& .leftContainer":{
        display: "inline-flex",
        width: "55%",
    },
    "& .peopleImage": {
        display: "flex",
        alignItems: "center",
        marginRight: "50.5px",
        "& .MuiAvatar-root": {
            height: "156px",
            width: "156px",
            borderRadius: "50%",
            "@media(max-width:1278px)": {
                height: "126px",
                width: "126px",
            },
            "@media(max-width:799px)": {
                height: "126px",
                width: "126px",
            },
            "@media(max-width:699px)": {
                height: "100px",
                width: "100px",
            }
        },
        "@media(max-width:780px)": {
            marginRight: "25px"
        }
    },
    "& .peopleAbout": {
        display: "flex",
        flexFlow: "column",
        overFlow: "hidden",
    },
    "& .name": {
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "39px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginRight: "43px",
        [theme.breakpoints.down('sm')]: {
            margin: "44px 0 0",
            fontSize: "20px",
            lineHeight: "24px"
        }
    },
    "& .btnGroup": {
        display: "flex",
        flexFlow: "column",
        margin: "10px auto 10px 0",
        [theme.breakpoints.down('xs')]: {
            flexFlow: "row",
            margin: "8px auto"
        },
        "& button": {
            height: "18px",
            width: "80px",
            fontSize: "12px",
            borderRadius: "2px",
            boxShadow: "none",
            "&:hover": {
                boxShadow: "none !important",
            }
        },
        "& .greenBtn": {
            height: "18px",
            background: "#25CF2C !important",
            color: "#0D0C22"
        },
        "& .blackBtn": {
            background: "#0D0C22 !important",
            color: "#fff",
        },
        "& button:nth-child(1)": {
            marginBottom: "4px",
            [theme.breakpoints.down('xs')]: {
                margin: "0 4px 0"
            }
        }
    },
    "& .profession": {
        fontSize: "14px",
        textAlign: "start",
        "@media (max-width: 600px)": {
            textAlign: "center"
        }
    },
    "& .earning": {
        margin: "10px auto 10px 0",
        fontSize: "14px",
        "@media (max-width: 600px)": {
            textAlign: "center",
            margin: "8px auto"
        }
    },
    "& .location": {
        display: "inline-flex",
        alignItems: "center",
        fontSize: "12px",
        "& svg": {
            height: "20px",
            width: "20px"
        },
        "& span": {
            marginLeft: "4px"
        }
    },
    "& .peopleProjects": {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "end",
        padding: "0px",
        width: "45%",
        "& .postGrid":{
            aspectRatio: "177/137",
        },
        "& img, video": {
            height: "100%",
            width: "100%",
            borderRadius: "4px",
            objectFit: "cover"
        }
    },

    "& .peopleCard": {
        borderRadius: "2px",
        display: "inline-flex",
        padding: "0px"
    },
    "& .people": {
        position: "relative",
        aspectRatio: "104/80"
    },
    "& .peopleImgCard": {
        width: "100%",
        height: "100%",
        boxShadow: "none",
        borderRadius: "1.14px"
    },
    "& .peopleBox": {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "64px",
        height: "64px",
    },
    "& .peopleAvatar": {
        width: '100%',
        height: '100%',
        border: `3px solid ${theme.palette.background.paper}`,
    }
}));

class FavouritePeopleList extends React.Component<Props>{
    constructor(props: Props){
        super(props);
    }
    
    renderPeopleForDesktop = () => {
        const {favouritesPeople} = this.props;
        return (
            <StyledPeopleContainer data-test-id="favouritePeopleDesktop">
               {favouritesPeople.map((favouritePeople: FavouritePeople) =>  {
                const avatarName = returnTruthyString(favouritePeople.firstName[0]) + returnTruthyString(favouritePeople.lastName[0])
                return <Box className="card" data-test-id="favouritePeopleCard">
                <div className="leftContainer">
                    <div className="peopleImage">
                        <Avatar
                            src={favouritePeople.photo}
                        >{avatarName}</Avatar>
                    </div>
                    <div className="peopleAbout">
                    {this.renderAbout(favouritePeople)}
                    </div>
                </div>
                <Grid className="peopleProjects" container spacing={1}>
                {favouritePeople.galleryImages.slice(0,3).map((imgurl: string) => {
                             const urlType = isImageOrVideo(imgurl);
                            return <Grid item md={4} sm={4} className="postGrid">{urlType === "image" ? <img alt="test" src={imgurl} />: <video src={imgurl} controls />}</Grid>
                        })}
                </Grid>
            </Box>
               })}
            </StyledPeopleContainer>
        )
    }

    getHourlyRate = (s1: string | null, s2: string | null) => {
        if(s1 === null || s2 === null){
            return "";
        }
        return `£${s1}/hr - £${s2}/hr`;
    }

    renderAbout = (favouritePeople: FavouritePeople) => {
        return <><Typography className="name">{`${favouritePeople.firstName} ${favouritePeople.lastName}`}</Typography>
        <div className="btnGroup">
            <Button variant="contained" className="greenBtn">Shortlist</Button>
            <Button variant="contained" className="blackBtn">Available</Button>
        </div>
        <Typography className="profession">{favouritePeople.headline}</Typography>
        <Typography className="earning">{this.getHourlyRate(favouritePeople.hourly_rate_from, favouritePeople.hourly_rate_to)}</Typography>
        <div className="location">
            <LocationOnIcon /> <span>{favouritePeople.county}, {favouritePeople.country}</span>
        </div>
        </>
    }

    renderPeopleList = () => {
        const {favouritesPeople, windowWidth} = this.props;
        const isMobile = windowWidth <= 599;
        if(favouritesPeople.length > 0){
            if(isMobile){
                return <StyledPeopleContainer data-test-id="favouritePeopleMobile">
                    {favouritesPeople.map((favouritePeople: FavouritePeople) => {
                        const avatarName = returnTruthyString(favouritePeople.firstName[0]) + returnTruthyString(favouritePeople.lastName[0])
                        return <div className="peopleListItem" data-test-id="favouritePeopleCard">
                        <Grid className="peopleCard" container spacing={1}>
                            <Grid className="people" item md={4} sm={4} xs={4}>
                                <img src={favouritePeople.galleryImages?.[0]} alt="peopleImgCard" className="peopleImgCard" />
                            </Grid>
                            <Grid className="people" item md={4} sm={4} xs={4}>
                                <img src={favouritePeople.galleryImages?.[1]} alt="peopleImgCard" className="peopleImgCard" />
                                <Box className="peopleBox">
                                    <Avatar
                                       src={favouritePeople.photo}
                                        className="peopleAvatar"
                                    >{avatarName}</Avatar>
                                </Box>
                            </Grid>
                            <Grid className="people" item md={4} sm={4} xs={4}>
                                <img src={favouritePeople.galleryImages?.[2]} alt="peopleImgCard" className="peopleImgCard" />
                            </Grid>
                        </Grid>
                        {this.renderAbout(favouritePeople)}
                    </div>
                    })}
             </StyledPeopleContainer>
            }else{
                return this.renderPeopleForDesktop();
            }
        }else{
            return <NoFavourites data-test-id="no-fav-people" title="You don’t have favourites people yet" />;
        }
    }

    render(){
        return this.renderPeopleList();
    }
}  

export default FavouritePeopleList;
